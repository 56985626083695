<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
      <KPI label="Total earned by streamers" :value="`€${totalEarned.toFixed(2)}`" />

      <KPI label="Total withdrawed" :value="`€${totalWithdrawed.toFixed(2)}`" />

      <KPI label="Withdraw count" :value="withdrawCount" />
    </div>

    <table v-if="earnings && earnings.length" class="w-full text-sm rounded border shadow-sm border-collapse">
      <thead>
        <th class="p-4 font-medium text-center border-b">
          <button class="font-bold" @click="viewYear = (parseInt(viewYear) - 1).toString()">
            <!-- eslint-disable-next-line vue/no-parsing-error -->
            {{ "<" }}
          </button>
          {{ viewYear }}
          <button class="font-bold" @click="viewYear = (parseInt(viewYear) + 1).toString()">></button>
        </th>

        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(0)">Total</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(1)">January</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(2)">February</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(3)">March</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(4)">April</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(5)">May</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(6)">June</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(7)">July</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(8)">August</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(9)">September</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(10)">October</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(11)">November</th>
        <th class="p-4 font-medium text-left border-b cursor-pointer" @click="switchSortOrder(12)">December</th>
      </thead>

      <tbody>
        <tr v-for="(contentCreator, contentCreatorId) of filteredEarnings" :key="contentCreatorId">
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            <a :href="`https://app.netwrk.gg/profile/${contentCreator.username}`" target="_blank">
              {{ contentCreator.username }}
            </a>
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{ contentCreator.earnedByYear[viewYear].toFixed(1) }}
          </td>

          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][1].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][2].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][3].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][4].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][5].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][6].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][7].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][8].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][9].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][10].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][11].toFixed(1)
                : 0
            }}
          </td>
          <td class="p-4 border border-slate-300 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            €{{
              contentCreator.earned[viewYear] !== null && contentCreator.earned[viewYear] !== undefined
                ? contentCreator.earned[viewYear][12].toFixed(1)
                : 0
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import { KPI } from "cavea-design-system";

export default {
  name: "AdminEarnings",

  components: {
    KPI,
  },

  data() {
    return {
      viewYear: new Date().getFullYear().toString(),
      earnings: [],
      sortKey: 0,
      // true = desc, false = asc
      sortDir: true,
      totalWithdrawed: 0,
      withdrawCount: 0,
      totalEarned: 0,
    };
  },

  computed: {
    filteredEarnings() {
      const earnings = this.earnings?.filter(
        (e) =>
          e?.username?.length &&
          e?.earnedByYear[this.viewYear] &&
          e?.earnedByYear[this.viewYear] !== undefined &&
          e?.earnedByYear[this.viewYear] !== null &&
          e?.earnedByYear[this.viewYear] >= 0.5
      );

      if (this.sortKey === -1) {
        return earnings?.sort((a, b) => (this.sortDir ? b?.username > a?.username : a?.username - b?.username));
      }

      if (this.sortKey === 0) {
        return earnings?.sort((a, b) =>
          this.sortDir
            ? b?.earnedByYear[this.viewYear] - a?.earnedByYear[this.viewYear]
            : a?.earnedByYear[this.viewYear] - b?.earnedByYear[this.viewYear]
        );
      }

      if (this.sortKey > 0 && this.sortKey <= 12) {
        return earnings?.sort((a, b) =>
          this.sortDir
            ? (b?.earned[this.viewYear][this.sortKey] ?? 0) - (a?.earned[this.viewYear][this.sortKey] ?? 0)
            : (a?.earned[this.viewYear][this.sortKey] ?? 0) - (b?.earned[this.viewYear][this.sortKey] ?? 0)
        );
      }

      return earnings;
    },
  },

  created() {
    this.fetchMonthlyEarnings();

    this.fetchAdminWalletMetrics();
  },

  methods: {
    async fetchMonthlyEarnings() {
      this.earnings = await axios
        .get(`${process.env.VUE_APP_API_URL}/streamer/earnings`)
        .then((res) => res?.data?.contentCreatorEarnings)
        .catch((error) => error?.response?.data?.contentCreatorEarnings);
    },

    /**
     * @param {number} sortKey
     */
    switchSortOrder(sortKey) {
      if (sortKey === this.sortKey) {
        this.sortDir = !this.sortDir;
      } else {
        this.sortKey = sortKey;
      }
    },

    async fetchAdminWalletMetrics() {
      const metrics = await axios
        .get(`${process.env.VUE_APP_API_URL}/v2/admin/wallet/metrics`)
        .then((res) => res?.data)
        .catch(() => undefined);

      this.totalWithdrawed = metrics?.total_withdrawed ?? 0;
      this.totalEarned = metrics?.total_earned ?? 0;
      this.withdrawCount = metrics?.withdraw_count ?? 0;
    },
  },
};
</script>
